import { alertService } from "@/services/alert/alert.service";
import { guestLoginService } from "@/services/auth/login/guest/login.service";
import Language from "./Language.vue";
import config from "@/config/app.config";
import LineLoginButton from "./line-login-button/LineLoginButton";
import axios from "axios";
import Vue from "vue";
export default {
    components: {
        Language,
        LineLoginButton,
    },
    data() {
        return {
            data: {
                email: "",
                password: "",
                auth_type: "guest",
            },
            error: {},
            line_config: {
                response_type: "code",
                client_id: config.line_api.client_id,
                client_secret: config.line_api.client_secret,
                redirect_uri: config.line_api.callback_uri,
            },
            line_error: false,
        };
    },
    async mounted() {
        await this.apiLineCallback();
    },
    methods: {
        async login$() {
            this.line_error = false;
            this.error = {};
            await guestLoginService
                .login(this.data)
                .then((response) => {
                    return this.$router.push({ name: "guest-dashboard.index" });
                })
                .catch((response) => {
                    this.error = response;
                    alertService.close();
                });
        },

        async result(res) {
            console.log(res);
        },

        async apiLineCallback() {
            this.line_error = false;
            if (
                this.$route.query.access_token &&
                this.$route.query.auth_type &&
                this.$route.query.id &&
                this.$route.query.token_type
            ) {
                alertService.loading();
                Vue.$storage.set("auth", {
                    access_token: this.$route.query.access_token,
                    auth_type: this.$route.query.auth_type,
                    id: this.$route.query.id,
                    token_type: this.$route.query.token_type,
                });
                return this.$router.push({ name: "guest-dashboard.index" });
            }

            if (this.$route.query.code && this.$route.query.code == 401) {
                this.line_error = true;
            }
        },
    },
};
