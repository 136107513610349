import api from '../../../../globals/client';
import config from '../../../../config/app.config';

var promise;

export default class GuestLoginService {
    login(data) {
        promise =  api.client.post(config.end_point.auth.login, data);
        return promise;
    }

    async lineCallback(data) {
        promise = await api.client.get(config.line_api.be_callback, {params: data});
        return promise;
    }
}

export const guestLoginService = new GuestLoginService();
