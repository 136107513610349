<template>
   <div>
      <div class="d-flex align-items-end flex-column font-weight-bold">
         <b-dropdown id="dropdown-right" right text="Language" variant="outline-secondary" class="m-2">
            <b-dropdown-item @click="localize('en')" class="text-center">English</b-dropdown-item>
            <b-dropdown-item @click="localize('ja')" class="text-center">Japanese</b-dropdown-item>
         </b-dropdown>
      </div>
   </div>
</template>

<script>
export default {
    name: 'Language',
    methods: {
        localize(lang) {
            this.$i18n.locale = lang;
        }
    }

}
</script>

<style scoped>
#dropdown-right {
    text-transform: capitalize !important;
}
</style>